import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nishchint-customer-onboarding-details',
  templateUrl: './nishchint-customer-onboarding-details.component.html',
  styleUrls: ['./nishchint-customer-onboarding-details.component.css']
})
export class NishchintCustomerOnboardingDetailsComponent implements OnInit {
  onboarding: boolean = false; // Control iframe visibility
  iframeSrc: SafeResourceUrl | null = null; // Store sanitized iframe source URL
  tokenObj: any; // Token object to store the response
  customerData:any
  constructor(private userservice: UsersService, private sanitizer: DomSanitizer, private route: Router) {}

  ngOnInit(): void {
    this.customerData = history.state.customerData;
    this.nishchintScreen(this.customerData); // Automatically call the function with the customer data
  }

  goBack () {
    this.route.navigate(['Dashboard/Task/MyTask'])
  }
  // Method to handle onboarding
  async nishchintScreen(customer: any): Promise<void> {
    try {
      // Get the token from the service
      //const tokenvalueData = await this.viewLoggedInPersonDetails();
      // Set onboarding flag to true (this can be used to show/hide iframe)
      this.onboarding = true;

      // Store the token in localStorage
     // localStorage.setItem('token', tokenvalueData);
     const customerSerialized = JSON.stringify(this.customerData);
     window.addEventListener('message', (event) => {
      if (event.data === 'goBack') {
        this.goBack();
      }
    });


      // Construct the URL with the query parameters
      const url = `https://nishchint.anvayaa.com/virtualOnboarding?employeeID=${customer.employeeID}&dependentID=${customer.dependentID}&relation=${customer.relation}&customer=${customerSerialized}`;
      // Sanitize the URL and store it
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (error) {
      console.error("Error during onboarding:", error);
    }
  }

  // Method to fetch the logged-in person's details (and the token)
  viewLoggedInPersonDetails(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.userservice.viewLoggedInPersonDetails().subscribe(
        (response) => {
          this.tokenObj = response;
          // Assuming the token is in response.token
          resolve(this.tokenObj.token);
        },
        (error) => {
          console.error("Error fetching token:", error);
          reject(error);
        }
      );
    });
  }
}
